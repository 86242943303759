.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 100vh;
    max-height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    margin: 0;
    padding: 2rem 0;
    background-color: #fff8e1;
    font-family: 'Poppins', sans-serif;
    background-image: linear-gradient(rgba(255, 248, 225, 0.8), rgba(255, 248, 225, 0.95)),
        url('../../public/images/restaurant_background.avif');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #5d4037;
    position: absolute;
    top: 0;
    left: 0;
}

.lobby-header {
    text-align: center;
}

.lobby-title {
    font-size: 4rem;
    font-weight: 800;
    margin: 0;
    color: #ff7043;
    text-shadow: 3px 3px 0px #5d4037;
    letter-spacing: 2px;
}

.lobby-subtitle {
    font-size: 2rem;
    margin-top: 0.5rem;
}

.homepage-actions {
    margin: 2rem 0;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
}

.homepage-actions button {
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.create-button {
    background-color: #4caf50;
    color: white;
    box-shadow: 0 4px 0 #388e3c;
}

.create-button:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 0 #388e3c;
}

.join-button {
    background-color: #2196f3;
    color: white;
    box-shadow: 0 4px 0 #1976d2;
    font-size: 2rem;
}

.join-button:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 0 #1976d2;
}

.wheel-picker-row {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
}

.code-image {
    max-width: 200px;
    max-height: 200px;
}

.code-images {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
}
